import revive_payload_client_sCA1SceicG from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_idb-keyval@6.2.1_rollup@2.79.1_sass@1.77.8_stylelint@16.8.1_typescri_chudnyyqacov7ew6l67afeklze/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_JyCeQoyEw2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_idb-keyval@6.2.1_rollup@2.79.1_sass@1.77.8_stylelint@16.8.1_typescri_chudnyyqacov7ew6l67afeklze/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7HXw6YcoSY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_idb-keyval@6.2.1_rollup@2.79.1_sass@1.77.8_stylelint@16.8.1_typescri_chudnyyqacov7ew6l67afeklze/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_YcB78g6YhS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@2.79.1_vite@5.4.1_vue@3.4.36/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import supabase_client_xN1bvT0pFK from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.3.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_ghPAsCzPvv from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_idb-keyval@6.2.1_rollup@2.79.1_sass@1.77.8_stylelint@16.8.1_typescri_chudnyyqacov7ew6l67afeklze/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_l13Upq1FI3 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_idb-keyval@6.2.1_rollup@2.79.1_sass@1.77.8_stylelint@16.8.1_typescri_chudnyyqacov7ew6l67afeklze/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_pJcGVSxYDM from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_idb-keyval@6.2.1_rollup@2.79.1_sass@1.77.8_stylelint@16.8.1_typescri_chudnyyqacov7ew6l67afeklze/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_sroCy8X8Xi from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_idb-keyval@6.2.1_rollup@2.79.1_sass@1.77.8_stylelint@16.8.1_typescri_chudnyyqacov7ew6l67afeklze/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_GCQvo99ER3 from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.3_rollup@2.79.1_typescript@5.5.4_vue@3.4.36/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_Swk2WdiAo2 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_idb-keyval@6.2.1_rollup@2.79.1_sass@1.77.8_stylelint@16.8.1_typescri_chudnyyqacov7ew6l67afeklze/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import preview_client_9Z5c7bpUqP from "/opt/buildhome/repo/node_modules/.pnpm/@nuxthq+studio@2.0.3_rollup@2.79.1/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import plugin_client_ZA1xqfx81S from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_rollup@2.79.1/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import floating_vue_EIcJ7xiw0h from "/opt/buildhome/repo/.nuxt/floating-vue.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/buildhome/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_tay6qBMmsZ from "/opt/buildhome/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.9.1_rollup@2.79.1_vite@5.4.1_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import auth_redirect_37V93RytoR from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.3.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import slideovers_U54JPujPRR from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_idb-keyval@6.2.1_rollup@2.79.1_vite@5.4.1_vue@3.4.36/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_lUgQ7yCRMZ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_idb-keyval@6.2.1_rollup@2.79.1_vite@5.4.1_vue@3.4.36/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_NfNrV5Qz8C from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_idb-keyval@6.2.1_rollup@2.79.1_vite@5.4.1_vue@3.4.36/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_FUzMblK75w from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_rollup@2.79.1/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_iuomiJdBdG from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.4.5_rollup@2.79.1_vite@5.4.1_vue@3.4.36/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_1gAtz6Etf2 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_idb-keyval@6.2.1_rollup@2.79.1_vite@5.4.1_vue@3.4.36/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import plugin_qrFZLrGP7b from "/opt/buildhome/repo/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.3_pinia@2.2.1_rollup@2.79.1/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import presets_X3ykYOcih6 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_idb-keyval@6.2.1_rollup@2.79.1_vite@5.4.1_vue@3.4.36/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_EDAt6NPwzm from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_idb-keyval@6.2.1_rollup@2.79.1_vite@5.4.1_vue@3.4.36/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import floating_zzW5zaa1LU from "/opt/buildhome/repo/plugins/floating.ts";
import sentry_3AyO8nEfhE from "/opt/buildhome/repo/plugins/sentry.ts";
import variables_M3EiEc4WpY from "/opt/buildhome/repo/plugins/variables.ts";
import plugin_auto_pageviews_client_un6dj57EwB from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_rollup@2.79.1/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_sCA1SceicG,
  unhead_JyCeQoyEw2,
  router_7HXw6YcoSY,
  _0_siteConfig_YcB78g6YhS,
  supabase_client_xN1bvT0pFK,
  payload_client_ghPAsCzPvv,
  navigation_repaint_client_l13Upq1FI3,
  check_outdated_build_client_pJcGVSxYDM,
  chunk_reload_client_sroCy8X8Xi,
  plugin_vue3_GCQvo99ER3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Swk2WdiAo2,
  preview_client_9Z5c7bpUqP,
  plugin_client_ZA1xqfx81S,
  floating_vue_EIcJ7xiw0h,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_tay6qBMmsZ,
  auth_redirect_37V93RytoR,
  slideovers_U54JPujPRR,
  modals_lUgQ7yCRMZ,
  colors_NfNrV5Qz8C,
  plugin_client_FUzMblK75w,
  plugin_iuomiJdBdG,
  scrollbars_client_1gAtz6Etf2,
  plugin_qrFZLrGP7b,
  presets_X3ykYOcih6,
  variables_EDAt6NPwzm,
  floating_zzW5zaa1LU,
  sentry_3AyO8nEfhE,
  variables_M3EiEc4WpY,
  plugin_auto_pageviews_client_un6dj57EwB
]