import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_eslint@9.9.0_idb-keyval@6.2.1_rollup@2.79.1_sass@1.77.8_stylelint@16.8.1_typescri_chudnyyqacov7ew6l67afeklze/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.1_idb-keyval@6.2.1_rollup@2.79.1_vite@5.4.1_vue@3.4.36/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}